import React, { useEffect, useMemo, useState } from 'react';
import { Navigate} from 'react-router-dom';
import Document from '../screen/document';
// import AddCard from '../screen/addCard';
import Module from '../screen/module/module';
import TimeTable from '../screen/timeTable';
import Course from '../screen/course/course';
import ViewCoc from '../screen/viewDoc';
import EditXls from '../screen/editXLS';
import Roles from '../screen/userMangement/roles';
import User from '../screen/userMangement/user';
import ViewModule from '../screen/module/viewModule';
import ModuleHistory from '../screen/module/historyModule';
import Permission from '../screen/permission/permission';
import NonAuth from '../layout/nonAuthLayout';
import HistoryCourse from '../screen/course/historyCourse';
import DocumentHistory from '../screen/documentHistory';
import DocumentTypes from '../screen/document/documentTypes';
import ModuleTypes from '../screen/module/moduleTypes';
import TestDoc from '../screen/TestDoc';


const PrivateRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("accessToken"));

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(!!localStorage.getItem("accessToken"));
    };

    window.addEventListener("storage", handleStorageChange);
    
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return isAuthenticated ? element : <Navigate to="/" replace />;
};


const Pagesroute = {
    path: '/',
    element: <NonAuth />,
    children: [
      { path: 'document', element: <PrivateRoute path="/document" element={<Document />} /> },
      { path: 'module', element:<PrivateRoute path="/module" element={<Module />} />  },
      { path: 'course', element:<PrivateRoute path="/course" element={<Course />} />  },
      { path: 'timeTable', element: <PrivateRoute path="/timeTable" element={<TimeTable />} /> },
      { path: 'viewDoc', element:<PrivateRoute path="/viewDoc" element={<ViewCoc />} />  },
      { path: 'editXLS', element:<PrivateRoute path="/editXLS" element={<EditXls />} />  },
      { path: 'user', element:<PrivateRoute path="/user" element={<User />} />  },
      { path: 'roles', element:<PrivateRoute path="/roles" element={<Roles />} />  },
      { path: 'viewModule', element:<PrivateRoute path="/viewModule" element={<ViewModule />} />  },
      { path: 'historyModule', element:<PrivateRoute path="/historyModule" element={<ModuleHistory />} />  },
      { path: 'permission', element:<PrivateRoute path="/permission" element={<Permission />} />  },
      { path: 'historyCourse', element:<PrivateRoute path="/historyCourse" element={<HistoryCourse />} />  },
      { path: 'documentHistory', element:<PrivateRoute path="/documentHistory" element={<DocumentHistory />} />  },
      { path: 'acessControll/documentTypes', element:<PrivateRoute path="/acessControll/documentTypes" element={<DocumentTypes />} /> },
      { path: 'acessControll/moduleTypes', element:<PrivateRoute path="/acessControll/moduleTypes" element={<ModuleTypes />} /> },
      { path: 'TestDoc', element:<PrivateRoute path="/TestDoc" element={<TestDoc />} /> },
      { path: '*', element: <Navigate to="/document" replace={true} /> },
    ],
  }
  
  
export default Pagesroute;

export { PrivateRoute };
import Modal from 'react-bootstrap/Modal';
import '../../css/Modal.css';
import { CloseButton } from 'react-bootstrap';


function ModalController(props) {
      // console.log(props);
  return (
    <>
      <Modal show={props.status} animation={props.animation? props.animation:false} size={props.size} centered={props.centered}>
        <Modal.Header>
          <div style={{display:'flex',width:"100%",flexDirection:"row",justifyContent:"space-between",alignItems:"center",alignContent:"center"}}>
          <Modal.Title >{
          props.title()
          }</Modal.Title>
          <CloseButton onClick={props.onHide} style={{marginRight:"6px"}}/>
          </div>
      
       {/* <button type="button" className="close" onClick={props.onHide}>
            <span aria-hidden="true">&times;</span>
          </button> */}
    
        </Modal.Header>
        <Modal.Body>
              {
                props.body()
              }
        </Modal.Body>
        <Modal.Footer>
              {
                props.footer()
              }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalController;
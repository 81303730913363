import { Table } from "react-bootstrap";
import '../../assets/Table.css';



const table = (props) => {
  // Ensure that props.data is an array or default to an empty array
  const data = Array.isArray(props.data) ? props.data : [];

  return (
    <div
    className="history-container"
      style={{
        height: '60vh',
        overflow: 'auto',
        border: '0.5px solid gray',
        marginBottom: '1rem',
        width: props.width || '100%',
        padding:"0px"
      }}
    >
      <Table striped bordered className="custom-table" style={{tableLayout:'fixed',width:'100%'}}>
        <thead style={{ backgroundColor: '#000F3F',position:'sticky',top:'-0.1px',marginTop:'-1px',zIndex:'888'}}>
          <tr style={{marginTop:'-10px'}}>
            {props.columns.map((hdata, index) => (
              <th
              
                key={index}
                style={{
                  color: 'white',
                  whiteSpace: 'nowrap',
                  backgroundColor: '#000F3F',
                  textAlign:'left',
                  // minWidth: '300px', maxWidth: '500px',
                  width: hdata.cellWidth || "150px",
                  // minWidth:'350px',
                  // maxWidth:"400px"
                  // minWidth: props.cellWidth || 'auto' ,
                  
                }}
              >
                {hdata.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((bdata, rowIndex) => (
            <tr key={rowIndex}>
              {props.columns.map((bhdata, colIndex) => (
                <td
                  key={colIndex}
                  style={{ alignItems:'center',alignContent:'center',textAlign:'left',
                    wordBreak: 'break-word', 
                    whiteSpace: 'pre-wrap', 
                    maxWidth: '150px', 
                    overflowWrap: 'break-word',
                  }}
                >
                  {bhdata.Call
                    ? bhdata.Call(bdata)
                    : bdata[bhdata.datan]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
   </div>
  );
};

export default table;

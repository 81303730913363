import apiInstance from "./baseApi"

export const getPermissions = async (roleId, moduleName) => {
    try {

      const response = await apiInstance.get(`/permission/getPermission`,{
        params: { roleId, moduleName },
        headers: {
          "authorization": `${localStorage.getItem('accessToken')}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching permissions:', error);
      throw error;
    }
  };
  
  // Save permissions for a role and module
  export const savePermissionsAPI = async (roleId, moduleName, permissions) => {
    try {
      const response = await apiInstance.post(`/permission/addPermission`, permissions, {
        params: { roleId, moduleName },
        headers: {
            "authorization": `${localStorage.getItem('accessToken')}`,
          },
      });
      return response.data;
    } catch (error) {
      console.error('Error saving permissions:', error);
      throw error;
    }
  };
import React, { useState,useEffect } from 'react';
import{TableComponent,FormButton,ModalComponent,InputField,CustomDropdownButton, SelectComponent} from '../component/index';
import { Button, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import CustomPagination from '../functions/pagination';
import { useLocation, useNavigate } from 'react-router-dom';
// import BasicMenu from '../component/index';
import { getAllDoc,addDoc,deleteDoc, getDocumentType, editDoc, EditeTitle, deleteDocuments, downloadDocList } from '../API/document.jsx';
import axios from 'axios';
import Sucessmsg from "../component/messagecomponent/successMessage.jsx";
import '../assets/Document.css';
import ErrorMessage from '../component/messagecomponent/errorMessage.jsx';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaRegEdit } from "react-icons/fa";
import ToolTipCmp from '../component/ToolTipCmp.jsx';
import { FaArrowDownAZ, FaArrowDownWideShort, FaArrowUpAZ, FaArrowUpShortWide, FaArrowUpZA } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { getPermissions } from '../API/permissionApi.jsx';

const arrowIconStyles = {
  cursor: 'pointer', // Change cursor to pointer when hovered over
  transition: 'color 0.3s, transform 0.3s', // Smooth transition for color and transform properties
};

const Document = () => {
  const navigate = useNavigate();
  // const [fileData, setFileData] = useState([]);
  const [document,setDocument] = useState('');
  const [error, setError] = useState('');
  const [currentPage,setCurrentPage]=useState(1);
  const [totalPage, setTotalPage]=useState('');
  const [load,setLoad]=useState(false);
  const [ids,setIds]=useState('');
  const[ser,setSer]=useState('');
  const [options,setOptions]=useState('');
  const [dltMessage,setDltMessage]=useState('');
  const [isChecked, setIsChecked] = useState(false);

  const [message, setMessage] = useState({
    sucessMessage:'',
    errorMessage:''
    
  });
  const [seacrchs,setSeacrchs]=useState('name');
  const [documentDetail,setDocumentDeatail] = useState({
    name:"",
    date: new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour12: true,
    }),
    docNumber:"",
    system:"",
    location:"",
    docTitle:"",
    owner:localStorage.getItem("userName"),
    filename:"",
    notes:"",
    logs:""
  });
  const location = useLocation();
  const { docId } = location.state || {};
  const [file, setFile] = useState(null);
  const [sorting,setSorting]=useState('');
  const [permission, setPermission]=useState({});
  const user = useSelector(state => state?.user?.authReducer?.loginResponse?.data);
  // console.log("user",user?.loginResponse?.data);
  
useEffect(()=>{
  fetchPermission();
},[user])

const fetchPermission=async()=>{
  console.log("user?.role",user);
  
  try{
    const data = await getPermissions(user?.role, "Document");
console.log("datadatadata",data);
setPermission(data?.data)

  }catch(err){

  }
}


  useEffect(() => {
    console.log("MSO-TC-1 Time_Table",seacrchs);
    
    allDoc('');
    getDocumentList();
  }, [docId,ser,sorting,seacrchs]);

const getDocumentList=async()=>{
  try{
    const result = await getDocumentType();
    console.log("result123", result);
    
    const mappedDocuments = result?.data?.data?.map((document) => ({
      label: document?.documentCode,
      value: document?.documentCode,
    }));
    const initialOption = { label: "Select the Name", value: "" };
    const options = [initialOption, ...mappedDocuments];
    
    setOptions(options);
  }
  catch (error) {
    console.log("error",error)
  }
}

//------------Sorting------------------//
const sortingDateandTitle=(val)=>{
 
    setSorting(val);
  

}

//-----------search------------------//
const [searching, setSearching]=useState();
const search = async(e)=>{
  setSorting('');
  const a = e.target.value;
  setSearching(a);
  
  setSer(`${seacrchs}=${a}`);
 
}


const handleChange=(e)=>{
  setError('');
setDocumentDeatail({
  ...documentDetail,
  [e.target.name]: e.target.value,
})
}

const handleSelect=(value)=>{
  setError('');
  setDocumentDeatail({
    ...documentDetail,
    name: value,
  })
}
//-------------------Downloadcsv-----------//
const Downloadcsv=async()=>{
  try{
    setLoad(true);
    const res = await downloadDocList();
    setLoad(false);
    if(res.status == 200){
      window.open(`${res?.data?.link}`,'_blank');
    }else{
      setMessage({
        message,
        errorMessage:"Somthing Went Wrong or Net Error"
      })
      setTimeout(() => {
        setMessage({
          errorMessage:""
        })
      }, 4000);
    }
    
  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing Went Wrong or Net Error"
    })
    setTimeout(() => {
      setMessage({
        errorMessage:""
      })
    }, 4000);
  }
}
//-------------extention-------------//
const allowedExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];

const handleFileChange = (event) => {
  setError('');

  const selectedFile = event.target.files[0];
  if (selectedFile) {
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    
    if (allowedExtensions.includes(`.${fileExtension}`)) {
      setFile(selectedFile);
    } else {
      console.log("bsdhv");
      setError('Invalid file type. Only .pdf, .doc, .xls, and .ppt files are allowed.');
    }
  }
};

const handlePageChange = (newPage) => {
  console.log('Page changed to:', newPage);
  allDoc(newPage);
};


  const allDoc=async(newPage)=>{
    console.log("kkkk",newPage,docId);
  try{
   setLoad(true);
      const res =await getAllDoc(newPage,docId,ser,sorting);
      setLoad(false)
    setDocument(res?.data?.data);
    setCurrentPage(res?.data?.pages?.currentPage);
    setTotalPage(res?.data?.pages?.totalPages);
    
    
  }catch(err){
    console.log(err);
  }
  }

//-------------------addDoc---------------------//
  const handleSubmit = async (event) => {
  event.preventDefault();
  if (load) {
    return;
  }
  if(documentDetail.name === ""){
    setError("Name is required");
  }else if(documentDetail.docTitle === ""){
    setError("Document Title is required");
  }else if(file === null || file === ""){
    setError("File is required");
  }else{
  const detail={
    name:documentDetail?.name,
    docTitle:documentDetail?.docTitle,
    owner:documentDetail?.owner,
    system:documentDetail?.system,
    location:documentDetail?.location,
    notes:documentDetail?.notes,
    docNumber:documentDetail?.docNumber,
    editorName:localStorage.getItem("userName"),
    date:new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour12: true,
    }),
    changedBy:localStorage.getItem("userName")
  }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentDetail', JSON.stringify(detail));
   try{
    setLoad(true);
     const result = await addDoc(formData);
     setLoad(false);
    console.log(result);
     if(result.status === 201){
      closeModel();
      setMessage({
        message,
        successMessage:result?.data?.message
      })
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
      setDocumentDeatail({
        name:"",
        date:new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
          hour12: true,
        }),
        system:"",
        location:"",
        docTitle:"",
        owner:localStorage.getItem("userName"),
        filename:"",
        notes:"",
        revision:"",
        docNumber:"",
        logs:''
      });
      setFile('');
      allDoc();
     }else{
      console.log("result",result);
      
      setMessage({
        message,
        errorMessage:result?.response?.data?.error
      })
      setTimeout(() => {
        setMessage({
          errorMessage:""
        })
      }, 4000);
     }
   }catch(err){
    console.log("hhh",err);
    
    setMessage({
      message,
      errorMessage:"Somthing Went Wrong or Net Error"
    })
    setTimeout(() => {
      setMessage({
        errorMessage:""
      })
    }, 4000);
   }
  }
  };

  //-------------DeleteDoc-----------------//
  const [dltMod,setDltMod]=useState([]);
  
  const handleDeleteDocuments=async()=>{
    console.log(ids,"l;ll");
    const data ={
      id:ids
    }
    setLoad(true);
    const res = await deleteDocuments(data);
  setLoad(false);
    if(res.status === 200){
      setModal1(false);
      setMessage({
        message,
        successMessage:"Document Deleted Successfully"
      })
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
      allDoc()
    }else if(res?.response?.data?.message === "Document Used in Module"){
      // setModal1(false);
      setMessage({
        message,
        errorMessage:"This document is currently in use within Module and cannot be deleted"
      })
      setTimeout(() => {
        setMessage({
          errorMessage:""
        })
      }, 4000);
      allDoc()
    }
    }

  const handleDelete=async(id)=>{
    setDltMessage('');
    setLoad(true);
    const res = await deleteDoc(id);
  console.log("yyy",res);
 if( res.data?.message != "Document not used in module" ){
  setDltMessage("This document cannot be deleted as it is already linked with below modules")
 }else{
  setDltMessage("Are you sure, you want to delete?")
 }
  setDltMod(res.data)
  setLoad(false);
    }
   
    const handleDescClick = (rowData) => {
     console.log("hhhhhhhhhhhhhhhhhhhhh");
    };
const[descedit,setDescEdit]=useState('');
const[fileedit,setfileedit]=useState('');
   const descEdit = (index) => {
    setError('');
    setfileedit('');
   console.log("sdfsf",index);
  setDescEdit(index);
  setDocumentDeatail({...documentDetail,desc:index.desc})
  // Other logic...
};

const fileEdit=(index)=>{
  setError('');
  setDescEdit('');
  setfileedit(index);
}


//--------------update doc----------------//
const updateFile=async(id)=>{
  console.log("sssss",documentDetail?.logs);
  if(file === null){
    setError("File is required");
  }else if(documentDetail?.logs === "" || documentDetail?.logs === undefined ){
    setError("Change Log is required");
  }else{
    const detail={
      id:descedit?._id,
      logs:documentDetail?.logs,
      date: new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        hour12: true,
      }),
    }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('documentDetail', JSON.stringify(detail));
      // console.log("formData",formData);
     try{
      setLoad(true);
       const result = await editDoc(formData);
       setLoad(false);
     
       if(result.status === 201){
        closeModel2();
        setMessage({
          message,
          successMessage:"File Replaced Successfully"
        })
        setTimeout(() => {
          setMessage({
            successMessage:""
          })
        }, 4000);
        setDocumentDeatail({
          name:"",
          date:new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
            hour12: true,
          }),
          system:"",
          location:"",
          docTitle:"",
          docNumber:"",
          owner:localStorage.getItem("userName"),
          filename:"",
          notes:"",
          revision:"",
          logs:""
        });
        setfileedit('')
        allDoc();
       }
     }catch(err){
      console.log(err);
     }
}
}

//----------Add Title ---------------//
const AddTitle=async()=>{
  console.log("bgfcfhvbdf",documentDetail);
  if(documentDetail?.docTitle === "" || documentDetail?.docTitle === undefined ){
    setError("Document Title is required");
  }else if(documentDetail?.logs === "" || documentDetail?.logs === undefined ){
    setError("Change Log is required");
  }else{
    const detail={
      id:documentDetail?._id,
      docTitle:documentDetail?.docTitle,
      logs:documentDetail?.logs
    }
   
     try{
      setLoad(true);
       const result = await EditeTitle(detail);
       setLoad(false);
       if(result.status === 200){
        closeModel2();
        setMessage({
          message,
          successMessage:"Document Title Updated Successfully"
        })
        setTimeout(() => {
          setMessage({
            successMessage:""
          })
        }, 4000);
        setDocumentDeatail({
          name:"",
          date:new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
            hour12: true,
          }),
          system:"",
          location:"",
          docTitle:"",
          owner:localStorage.getItem("userName"),
          filename:"",
          notes:"",
          docNumber:"",
          revision:"",
          logs:""
        });
        setModal3(false)
        allDoc();
       }else{
        setMessage({
          message,
          errorMessage:result?.response?.data?.message
        })
       }
     }catch(err){
      console.log(err);
     }
}
}

var columns = [
  {
      datan: 'name',
      name:  (
        <>
          Name {sorting === "nameasc"?
        <FaArrowDownAZ size={24}  onClick={() => sortingDateandTitle("namedsc")} className='Document-createDateasc'/>
      :
          <FaArrowUpAZ  size={24}  onClick={() => sortingDateandTitle("nameasc")} className='Document-createDateasc'/>
        }
        </>
      )
      
      ,
      Call: function(rowData){
        return(
          <>
          <ToolTipCmp text="Edit Document">
         <Button
          onClick={() =>  handleItemClick1(rowData?.googlePath,rowData?.googleFileId,rowData?._id) }
          // disabled={permission?.Edit ? false : true}
          className="myButton"
          style={{minWidth:'100px'}}
         >
         {rowData?.name}
      </Button>
      </ToolTipCmp>
          </>
        )
      }
     
  },
 {
  datan: `docTitle`,
  name:'Doc Title',
  Call: function (rowData) {
    return(
     <>
     <div style={{display:'flex',flexDirection:"row",gap:'6px',alignItems:'center'}}>
     <ToolTipCmp text="Edit Doc Title">
     <FaRegEdit onClick={() => openModel3(rowData)}/>
    </ToolTipCmp>
     <p style={{ margin: '0',padding:"0",textAlign:'left' }}>
    {rowData?.docTitle}
     </p>
     
     </div>

     </>
    ) 
   }
},
{
  datan: 'fileName',
  name: 'Files',
  cellWidth:"200px",
   Call: function (rowData) {
    return (
      <>
      <div style={{display:'flex',flexDirection:"row",gap:'6px',alignItems:'center'}}>
   
      <ToolTipCmp text="Change Document">
      {/* <FaRegEdit onClick={() => permission["File Replace"] ? openModel2(rowData) : null} /> */}
      <FaRegEdit onClick={() => openModel2(rowData)} /> 
      </ToolTipCmp>
   
        
        {fileedit === rowData ?  <InputField
  name="fileName"
  required
  type="file"
  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
  onChange={handleFileChange}
  style={{marginLeft:"3px"}}
/> : <p style={{ margin: '0',padding:"0",textAlign:'left' }}>{rowData?.fileName}</p>}

      </div>
      {fileedit === rowData ?
      <> 
      {error === "File is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: error === "Invalid file type. Only .pdf, .doc, .xls, and .ppt files are allowed." ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p> :null}
      </>
      :
      null}
      </>
    );
  },
},
{
  datan: 'recentRevision',
  // name: 'Revision Code ',
  cellWidth:'110px',
  name:  (
    <>
      Rev Code {sorting === "revasc"?
    <FaArrowUpShortWide size={24}  onClick={() => sortingDateandTitle("revdsc")} className='Document-createDateasc'/>
  :
      <FaArrowDownWideShort  size={24}  onClick={() => sortingDateandTitle("revasc")} className='Document-createDateasc'/>
    }
    </>
  )
  

},
{
  datan: 'recentReevDate',
  // name: 'Revision Date',
  
  name: (
    <>
    Rev Date 
    {sorting === "recentReevDateasc"?
    
      <FaArrowUpShortWide size={24}  onClick={() => sortingDateandTitle("recentReevDatedsc")} className='Document-createDateasc'/>
    :
        <FaArrowDownWideShort size={24}  onClick={() => sortingDateandTitle("recentReevDateasc")} className='Document-createDateasc'/>
      }
    </>
  ),
  Call: function (rowData) {
   return(
    <>
   {moment.tz(rowData?.recentReevDate, "America/Toronto").format("YYYY-MM-DD HH:mm:ss")}
    </>
   ) 
  }
},
{
  datan: '',
  name: 'Change log',
  cellWidth:'300px',
  Call: function (rowData) {
    return(
     <>
      <p>{rowData?.revision[rowData?.revision.length-1].logs}</p>
     </>
    ) 
   }
},

  {
      datan: 'dateAndTime',
      name: (
        <>
        Created Date 
        {sorting === "createDateasc"?
        
          <FaArrowUpShortWide size={24}  onClick={() => sortingDateandTitle("createDatedsc")} className='Document-createDateasc'/>
        :
            <FaArrowDownWideShort size={24}  onClick={() => sortingDateandTitle("createDateasc")} className='Document-createDateasc'/>
          }
        </>
      ),
      Call: function (rowData) {
        return(
         <>
        {moment.tz(rowData?.dateAndTime, "America/Toronto").format("YYYY-MM-DD")}
         </>
        ) 
       }
      
  },

// {
//   datan: 'notes',
//   name: 'Notes',
// },
{
  datan: 'action',
  name: 'Action',
  cellWidth:'100px',

  Call: function (rowData) {
    return <>
    {rowData?.disabled ?
    <button>Enable</button>:
      <CustomDropdownButton
        menuItems={[
          // ...(permission?.View
          //   ? [{ label: "View Document", onClick: () => handleViewModuleClick(rowData?.googleViewPath, rowData?.fileName) }]
          //   : []),
          { label: 'View Document', onClick: () => handleViewModuleClick(rowData?.googleViewPath,rowData?.fileName) },
          // { label: 'Edit Document',  onClick: () => handleItemClick1(rowData?.googlePath) },
      //     ...(permission?.Delete ?  [{ label: 'Delete Document', onClick:()=>openModel1(rowData._id)}]
      //   :
      //   []
      // ),
      { label: 'Delete Document', onClick:()=>openModel1(rowData._id)},
          // { label: 'Delete Document', onClick:()=>openModel1(rowData._id)},
          { label: 'View Document History', onClick:()=>navigate("/documentHistory", {state:{ids:rowData._id}})}
        ]}
      />}
    </>;
  }
}
];


const handleViewModuleClick = (url,filename) => {
  navigate(`/viewDoc`, { state: { data:url,name:filename} });
};



const handleItemClick1 = (url,id,docid) => {
  // console.log(url,"hhhhhhhhhh");
  navigate(`/editXLS`,{ state: { path:url,Gid:id,docid:docid} });

};


const [set,setModel] = useState(false);
const openModel = () =>{setModel(true)}
const closeModel = () =>{setModel(false);setError('');setIsChecked(false);setDocumentDeatail({
  name:"",
  date:new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
    hour12: true,
  }),
  system:"",
  location:"",
  docTitle:"",
  owner:localStorage.getItem("userName"),
  filename:"",
  notes:"",
  docNumber:"",
  revision:"",
  logs:""
});}


  function body(){
      return<>
      <Container>
      <Row className="g-1">
        <Col md={12} lg={6}>
       <SelectComponent label={'Document Name'} 
       required={true}
    value={documentDetail.name}
    options={options}
onChange={(value)=>handleSelect(value)}
/> 
<div className="container">
{/* <Form.Check
        type="checkbox"
        id="default"
        label="Do you want Custom Document Number"
        checked={isChecked}
        // onChange={(event)=>{setIsChecked(event.target.checked);setDocumentDeatail({
        //   ...documentDetail,
        //   docTitle:''
        // })}}
        onChange={(event) => {
          setIsChecked(event.target.checked);
          setDocumentDeatail((prevState) => ({
            ...prevState, // Keep all other properties unchanged
            docTitle: '',  // Set only the docTitle key to an empty string
          }));
        }}
        
      /> */}
      <Form.Check
  type="checkbox"
  id="default"
  label="Do you want Custom Document Number"
  checked={isChecked}
  onChange={(event) => {
    setIsChecked(event.target.checked);
    setDocumentDeatail((prevState) => ({
      ...prevState, // Keep all other properties unchanged
      docNumber: '',  // Set only the docTitle key to an empty string
    }));
  }}
/>

      </div>

       {error === "Name is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
      <InputField
        label="Created on Date & Time"
        name="date"
        required
        readOnly
        // type='date'
        value={documentDetail.date}
        onChange={handleChange}
      />
       {/* <InputField
        label="System"
        name="system"
        readOnly
        value={documentDetail.system}
        onChange={handleChange}
      /> */}
     {/* <InputField
        label="Notes"
        name="notes"
        value={documentDetail.notes}
        onChange={handleChange}
      /> */}
      {/* </Stack> */}
      </Col>
        <Col md={12} lg={6}>
        {/* <Stack gap={2} style={{height:'60vh',overflow:'auto'}}> */}
        <InputField
        label="Document Title"
        name="docTitle"
        placeholder='Enter document title'
        required
        value={documentDetail.docTitle}
        // onChange={handleChange}
        onChange={(e) => {
          const value = e.target.value.replace(/[\/\\]/g, '');
          handleChange({ target: { name: 'docTitle', value } });
        }}
      />
         {error === "Document Title is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}

         {isChecked ? 
           <InputField
           label="Document number"
           name="docNumber"
           placeholder='Enter document number'
           required
           value={documentDetail.docNumber}
           // onChange={handleChange}
           onChange={(e) => {
            
            
            const value = e.target.value.replace(/[^0-9]/g, ''); 
            console.log("e.target.value",value);

            handleChange({ target: { name: 'docNumber', value } });
          }}
         />
      :
      null
      }
    <InputField
  label="File"
  name="fileName"
  required
  type="file"
  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
  onChange={handleFileChange}
/>
       {error === "File is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
      </Col>
      </Row>
      </Container>
      </>
  }
  function footer (){
      return<>
     <Stack direction="horizontal" gap={2}>
          <FormButton onClick={closeModel}>Close</FormButton>
          <FormButton onClick={handleSubmit}>Save</FormButton>
      </Stack>
      </>
  }

  function header (){
    return<>
   <Stack direction="horizontal" gap={2}>
        <h3>Add Document</h3>
    </Stack>
    </>
  }


//----------modal 2 -----------//
const [modal1,setModal1]=useState(false);
const openModel1 = (id) =>{
  console.log("hhhhh",id);
  handleDelete(id);
  setModal1(true)
  setIds(id);
}
const closeModel1 = () =>{
  setDltMessage('');
  setDltMod(['']);
  setModal1(false)
}

function body1(){
  console.log("dltMod",dltMod);
  return<>
  <Container>
     {dltMessage === "This document cannot be deleted as it is already linked with below modules" ?<p style={{color:'red'}}>{dltMessage}</p>  : dltMessage} 
  </Container>
  <div style={{display:'flex',flexDirection:'column',maxHeight:'150px', overflowY:'auto'}}>
    <ol>
    {dltMod?.data?.map((item)=>(
<li>
 {item.name}
</li>
  ))
  }
    </ol>
  </div>
  </>
}
function footer1 (){
  return<>
 <Stack direction="horizontal" gap={2}>
      <FormButton onClick={closeModel1}>Close</FormButton>
    {dltMod?.message != "Document not used in module" ?  <FormButton disabled onClick={handleDeleteDocuments}>Delete</FormButton>: <FormButton  onClick={handleDeleteDocuments}>Delete</FormButton> } 
  </Stack>
  </>
}
function header1 (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h4>Delete Document</h4>
  </Stack>
  </>
}

//--------------modal3------------//
const [modal2,setModal2]=useState(false);

const openModel2 = (id) =>{
  setDescEdit(id);
  setModal2(true)
}
const closeModel2 = () =>{
  setError('');
  setFile(null)
  setModal2(false)
}

function body2(){
  return<>
  <Container>
  <InputField
   label="File Name"
  name="fileName"
  required
  type="file"
  accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
  onChange={handleFileChange}
/> 
{error === "File is required" ? <p style={{marginLeft:"10px",color:"red"}}>{error}</p>:null}
<div style={{marginLeft:"10px"}}>
<Form.Label >
   
Change log <span style={{color:"red"}}>*</span>
         </Form.Label>
   <Form.Control
             as="textarea"
             placeholder="Write Description here"
             label="File Name"
             style={{ height: '100px',width:"98%" }}
             value={documentDetail?.logs} name='logs' onChange={handleChange}
           />
           {error === "Description is required" ? <p style={{color:"red"}}>{error}</p>:null}
          
</div>  

  </Container>
  </>
}
function footer2 (){
  return<>
 <Stack direction="horizontal" gap={2}>
      <FormButton onClick={closeModel2}>Close</FormButton>
      <FormButton onClick={updateFile}>Submit</FormButton>
  </Stack>
  </>
}
function header2 (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h4>Replace File</h4>
  </Stack>
  </>
}

//-------------------modal4-------------------------//
const [modal3,setModal3]=useState(false);

const openModel3 = (rowData) =>{
  setDocumentDeatail(rowData);
  setModal3(true)
}
const closeModel3 = () =>{
  setError('');
  setFile(null)
  setModal3(false)
}

function body3(){
  return<>
  <Container>
    <Row className="g-1">
      <Col>
      <InputField
        label="Document Title "
        name="docTitle"
        required
        value={documentDetail.docTitle}
        onChange={(e) => {
          const value = e.target.value.replace(/[\/\\]/g, '');
          handleChange({ target: { name: 'docTitle', value } });
        }}
      
      />
{error === "File is required" ? <p style={{marginLeft:"10px",color:"red"}}>{error}</p>:null}
<div style={{marginLeft:"12px",marginTop:"5px"}}>
<Form.Label >
   
   Change log <span style={{color:"red"}}>*</span>
            </Form.Label>
<Form.Control
             as="textarea"
             placeholder="Write Description here"
             label="File Name"
             style={{ height: '100px',width:"97%" }}
             value={documentDetail?.logs} name='logs' onChange={handleChange}
           />
         {error === "Change Log is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
         </div>
      </Col>
    </Row>
 

  </Container>
  </>
}
function footer3 (){
  return<>
 <Stack direction="horizontal" gap={2}>
      <FormButton onClick={closeModel3}>Close</FormButton>
      <FormButton onClick={AddTitle}>Submit</FormButton>
  </Stack>
  </>
}
function header3 (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h4>Update Document Title</h4>
  </Stack>
  </>
}



  return (
    <div >
       {load === true && (
      <div className="spinner-container">
      <div className="text-center">
      <Spinner
        animation="border"
        role="status"
        style={{
          borderWidth: '2px', // Increase border width for a thicker circle
          width: '60px',   // Set the desired width
          height: '60px',  // Set the desired height (equal to width for a circle)
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div>Loading...</div>
    </div>
    </div>
    )}
      
            <Sucessmsg message={message?.successMessage || ''}/>
            <ErrorMessage message={message?.errorMessage || ''} />
        <div style={{width:'100%',display:'flex',justifyContent:'end',marginBottom:'10px'}}>
        <ModalComponent
        title={header}
        status={set}
        onHide={closeModel}
        footer= {footer}
        body= {body}
        animation={true}
       />
         <ModalComponent
        title={header1}
        status={modal1}
        onHide={closeModel1}
        footer= {footer1}
        body= {body1}
        animation={true}
       />
          <ModalComponent
        title={header2}
        status={modal2}
        onHide={closeModel2}
        footer= {footer2}
        body= {body2}
        animation={true}
       />
           <ModalComponent
        title={header3}
        status={modal3}
        onHide={closeModel3}
        footer= {footer3}
        body= {body3}
        animation={true}
       />
       
 {/* <input type='text' onChange={search} addonAfter={<p style={{color:'red'}}>hjgjgjg</p>}/> */}
 <div style={{ width: '100%'}}> 
 <Row >
  <Col xl={4} lg={4} md={6} sm={12} style={{marginBottom:"2px",}}>
  <InputGroup style={{ width: '100%',boxShadow:'none'}} >
      <InputGroup.Text style={{height:'40px'}}>
        {/* <SearchOutlined /> */}
        <Form.Select aria-label="Default select example" style={{width:'100%'}} value={seacrchs} onChange={(e)=>{
          setSeacrchs(e.target.value); 
          console.log("searching",searching);
          
          setSer(searching != "" && searching != undefined ? `${e.target.value}=${searching}`:'');
        }}>
      <option value="name">Name</option>
      <option value="title">Doc Title</option>
    </Form.Select>
      </InputGroup.Text >
      <FormControl
       style={{boxShadow:'none',height:'40px'}}
        placeholder={seacrchs === "name" ? "Enter Name":"Enter Doc title"}
        onChange={search}
      />
    </InputGroup>

  {/* <InputField
        width={'100%'}
        style={{minWidth:'250px',maxWidth:'300px'}}
        padding={'0px'}
        icon={<SearchOutlined />}
        onChange={search}
        placeholder='Enter Name, Doc title'
      /> */}
  </Col>
  <Col xl={2} lg={2} md={0} sm={0} />
  <Col xl={3} lg={3} md={6} sm={12}>
  <FormButton
        width='100%'
        
        variant="primary"
        onClick={Downloadcsv}
      >
   Download csv</FormButton>
  </Col>
  <Col xl={3} lg={3} md={6} sm={12}>
  {/* {permission?.Add ? */}
  <FormButton
        width='100%'
        
        variant="primary"
        onClick={() => {openModel();}}
      >
        Add New Document
      </FormButton>
      {/* :
      null
} */}
  </Col>
 </Row>
</div>

      
        </div>
        <div >
        <TableComponent
         columns={columns}
          data = {document}
         />
        </div>
      
        <CustomPagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
    </div>
    
  );
};

export default Document;

import React, { useEffect, useState } from 'react';
import { TableComponent, FormButton, ModalComponent, InputField, CustomDropdownButton, SelectComponent, CheckBoxComponent } from '../../component/index';
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row, Spinner, Stack, Table } from 'react-bootstrap';
import CustomPagination from '../../functions/pagination';
import { useNavigate } from 'react-router-dom';
import '../../assets/module.css';
import ErrorMessage from '../../component/messagecomponent/errorMessage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// import Select from 'react-select';
// import { colourOptions } from '../data';
// import BasicMenu from '../component/index';
// import axios from 'axios';
import { getAllDocName,getAllModule,addModule,editModule, deleteMod, getModuleType, deleteModAlert } from '../../API/moduleApi';
import SuccessMessage from '../../component/messagecomponent/successMessage';
import Select from 'react-select';
import ToolTipCmp from '../../component/ToolTipCmp';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { FaArrowDownAZ, FaArrowUpAZ } from 'react-icons/fa6';

const Module = () => {
  const navigate = useNavigate();
  const [options,setOptions]=useState([]);
  const [option,setOption]=useState([]);
  const [module, setModule]=useState('');
  const [load,setLoad]=useState(false);
  const [title,setTitle]=useState('');
  const [currentPage,setCurrentPage]=useState(1);
  const [totalPage, setTotalPage]=useState('');
  const [message, setMessage] = useState({
    successMessage:'',
    errorMessage:''
  });
  const [sorting,setSorting]=useState('');
  const[ser,setSer]=useState('');
  const [dltMessage,setDltMessage]=useState('');
  const [dltMod,setDltMod]=useState('');
  const [ids,setIds]=useState('');
  const [set, setModel] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [moduleValue,setModuleValue]=useState({
    name:'',
    desc:'',
    status:'',
    type:'',
    duration:'00',
    tabText:'',
    active:false,
    notes:'',
    fileid:[],
    id:'',
    moduleNumber:""
  });
 
  const [error, setError]=useState('');
  useEffect(()=>{
    getAllModules(currentPage);
    getAllDocsName();
    getModuleList();
  },[])

  useEffect(()=>{
    getAllModules(currentPage);
  },[set,ser,sorting])

  const getAllDocsName =async()=>{
    const res = await getAllDocName();
    console.log("kkkkkkkkkkkkkkkkkkkkk",res?.data?.data);
    const mappedDocuments = res?.data?.data.map((document) => ({
      label: document?.name +":"+ document.docTitle,
      value: document?._id,
      data:document?.pack,
      google:document?.googleFileId,
      Revision:document?.recentRevision,
      docTitles:document?.docTitle,
      recentReevDate:document?.recentReevDate
    }));
    setOptions(mappedDocuments);
  }

  const getModuleList=async()=>{
    try{
      const result = await getModuleType();
      console.log("result123", result?.data?.data);
      
      const mappedDocuments = result?.data?.data?.map((module) => ({
        label: module?.moduleCode,
        value: module?.moduleCode,
        active: module?.active
        
      }));
      const initialOption = { label: "Select the Type", value: "" };
      const options = [initialOption, ...mappedDocuments];
      
      setOption(options);
    }
    catch (error) {
      console.log("error",error)
    }
  }
 
// useEffect(()=>{
//   const defaultOptions = options?.filter(option => moduleValue?.fileid?.includes(option?.value));
//   setSelectedOptions(defaultOptions?.map(option => option?.value))
// },[moduleValue?.fileid])
   



const getAllModules =async(newPage)=>{
  setLoad(true);
  const id="";
  // console.log("ser,sorting",ser,sorting);
  const res = await getAllModule(newPage,id,ser,sorting);
  console.log(res);
 setLoad(false);
  setModule(res?.data?.data);
  console.log("kkk",res?.data?.pages?.currentPage,res?.data?.pages?.totalPages);
  setCurrentPage(res?.data?.pages?.currentPage);
  setTotalPage(res?.data?.pages?.totalPages);
}
const handlePageChange = (newPage) => {
  getAllModules(newPage)
};

//--------------rrr--------------//
const [selectedOptions, setSelectedOptions] = useState([]);
  const [rows, setRows] = useState([{ documentId: '', fileTitle:'',dup: false, threeHole: false, stpl: false,sm: false,t_pk:false,t_sld:false,exam:false,quantity:'',googleFileId:'',recentRevision:'',recentReevDate:"",docTitles:""}]);
  const [row, setRow] = useState([0]);
  const quqntity = [
    { label: 'One', value: 'One' },
    { label: 'One per student', value: 'One per student' },
    { label: 'One per student + one', value: 'One per student + one' },
    { label: 'Two', value: 'Two' },
    { label: 'Two per student', value: 'Two per student' },
    { label: 'Zero', value: 'Zero' },
  ];

  const handleInputChange = (e, index, field) => {
    console.log(field);
    if (index < 0 || index >= rows.length) {
      setMessage({
        message,
        errorMessage:`Select content first`
      })
     
      return;
    }
    const updatedRows = [...rows];
    if (field === "height") {
      updatedRows[index][field] = e.target.value;
     
    } else {
      updatedRows[index][field] = e.target.checked; 
    }
    setRows(updatedRows);
  };


  const handleSelectChanges = (index, selectedOption,field,fileTitle) => {
    setError('');
    console.log("cdcdcddc",selectedOption?.label.split(":")[0]);
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: selectedOption?.value,
      [fileTitle]:selectedOption?.label.split(":")[0],
      quantity:'One',
      dup:selectedOption?.data?.DEF_Duplex,
      threeHole:selectedOption?.data?.DEF_3Hole,
      stpl:selectedOption?.data?.DEF_Stapled,
      sm:selectedOption?.data?.DEF_StudentManual,
      t_pk:selectedOption?.data?.DEF_Tutorpack,
      t_sld:selectedOption?.data?.DEF_TutorSI,
      googleFileId:selectedOption?.google,
      recentRevision:selectedOption?.Revision,
      recentReevDate:selectedOption.recentReevDate,
      docTitles:selectedOption?.docTitles
    };
    setRows(updatedRows);
    if(field === "documentId"){
   
      if (rows.length - 1 === index) {
    
        setRows([...updatedRows, { documentId: '', fileTitle:'',dup: false, threeHole: false, stpl: false,sm: false,t_pk:false,t_sld:false,exam:false,quantity:'',googleFileId:'',recentRevision:'',recentReevDate:"",docTitles:""}]);
      }
    }
 
   
    
  };



  const handleSelectQuantity = (index, selectedOption, FileTitle) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      quantity: selectedOption.value,
    };
    setRows(updatedRows);
  };

  //-----------search------------------//

const search = async(e)=>{
  setSorting('');
  const a = e.target.value;
  console.log(a,"gttt");
  setSer(a);
 
}
//------------Sorting------------------//
const sortingDateandTitle=(val)=>{
 
  setSorting(val);


}
//----------------handle changes and add Module API-------------------//

const handleSelectChange=(value,type)=>{
  setError('');
  if(type === "status"){
    setModuleValue({
      ...moduleValue,
      status:value
    })
  }else if(type === "type"){
    setModuleValue({
      ...moduleValue,
      type:value
    })
  }else{
    value.forEach((val) => {
      setModuleValue({
        ...moduleValue,
        fileid: [ ...moduleValue.fileid, val?.value],
      });
    });
  }
}

const handleSelect=(value)=>{
  const selectedObject = option.find(item => item.value === value);
  console.log(selectedObject,value);
  setError('');
  setModuleValue({
    ...moduleValue,
    name: value,
    active:selectedObject?.active
  })
}


const handleChange = (e) => {
  setError('');
  const { name, value } = e.target;

  if (name === "tabText" && value.length > 28) {
    setError('Tab text must be 28 characters or less.');
    return; 
  }
  setModuleValue({
    ...moduleValue,
    [name]: value,
  });
};

//-----------remove index---------//
const [mod3, setMod3]=useState(false);

const removeIndex = (row) => {
  console.log("rowrow", row);
  if(!row.documentId){
    return;
  }{
    openModel3(row);
  }

};



const saveModel=async()=>{
  try{
  // console.log("k,lkfvf",rows);
  // const filteredData = rows.filter(item => item.documentId !== "" && Object.keys(item).length > 0);
  const filteredData = rows.filter(
    item => item && typeof item === 'object' && item.documentId && Object.keys(item).length > 0
  );
  
  
  // console.log("ddddddd",filteredData);

  if(moduleValue.name === ""){
    setError("Name is required");
  }else if(moduleValue.status === ""){
    setError("Status is required");
  }else if(moduleValue?.type === ""){
    setError("Type is required");
  }else if(filteredData.length < 1){
    setError("Select Document");
  }else if(!moduleValue?._id){
    // console.log("jkhhhhh");
const ownername = localStorage.getItem("userName");
const data ={
  name: moduleValue.name,
  desc: moduleValue.desc,
  status: moduleValue.status,
  type: moduleValue.type,
  duration: moduleValue.duration,
  tabText: moduleValue.tabText,
  active: moduleValue.active,
  notes: moduleValue.notes,
  moduleNumber:moduleValue?.moduleNumber,
  // fileid: moduleValue.fileid,
  editorName: ownername,
  moduleDocument:filteredData
}
setLoad(true);
const result = await addModule(data);
setLoad(false);
if(result.status === 201){
  closeModel();
  setMessage({
    message,
    successMessage:result?.data?.message
  })
  setTimeout(() => {
    setMessage({
      successMessage:""
    })
  }, 4000);
  setModuleValue({
    name:'',
    desc:'',
    status:'',
    type:'',
    duration:'',
    tabText:'',
    active:false,
    notes:'',
    moduleNumber:"",
    fileid:[]
  });
  setIsChecked(false);
  getAllModules();
 }else{
  // setMessage({
  //   message,
  //   errorMessage:result?.response?.data?.error
  // })
  setMessage({
    message,
    errorMessage: typeof result?.response?.data?.error === "string"
      ? result?.response?.data?.error
      : JSON.stringify(result?.response?.data?.error)
  });
 }
  }else{
    const ownername = localStorage.getItem("userName");
const data ={
  name: moduleValue.name,
  desc: moduleValue.desc,
  status: moduleValue.status,
  type: moduleValue.type,
  duration: moduleValue.duration,
  tabText: moduleValue.tabText,
  active: moduleValue.active,
  notes: moduleValue.notes,
  // moduleNumber:moduleValue?.moduleNumber,
  // fileid: moduleValue.fileid,
  editorName: ownername,
  moduleDocument:filteredData,
  id:moduleValue?._id
}
setLoad(true);
const result = await editModule(data);
setLoad(false);
if(result.status === 201){
  closeModel();
  setMessage({
    message,
    successMessage:result?.data?.message
  })
  setTimeout(() => {
    setMessage({
      successMessage:""
    })
  }, 4000);
  setModuleValue({
    name:'',
    desc:'',
    status:'',
    type:'',
    duration:'',
    tabText:'',
    active:false,
    notes:'',
    moduleNumber:"",
    fileid:[]
  });
  getAllModules();
 }
console.log("ressbcjhdsb");
  }
}catch(err){
  setMessage({
    message,
    errorMessage: err === "string"
      ? err
      : JSON.stringify(err)
  });
}
}




  var columns = [
    {
      datan: 'name',
      // name: 'Name',
      name:  (
        <>
          Module Name {sorting === "nameasc"?
        <FaArrowDownAZ size={24}  onClick={() => sortingDateandTitle("namedsc")} className='Document-createDateasc'/>
      :
          <FaArrowUpAZ  size={24}  onClick={() => sortingDateandTitle("nameasc")} className='Document-createDateasc'/>
        }
        </>
      ),
      Call: function(rowData){
        return(
          <>
             <ToolTipCmp text="Edit Module" widths={"120px"}>
         <Button
          onClick={() => handleItemClick1(rowData)}
          className="myButton"
         >
         {rowData?.name}
      </Button>
      </ToolTipCmp>
          </>
        )
      }
     
  },
    {
      datan: 'desc',
      name: 'Description',
    },
    {
      datan: 'status',
      name: 'Status',
    },
    {
      datan: 'type',
      name: 'Type',
      cellWidth:'100px'
    },
    {
      datan: 'duration',
      name: 'Duration',
      cellWidth:'100px'
    },
    {
      datan: 'tabText',
      name: 'Tab Text',
    },
    {
      datan: 'active',
      name: 'Active / InActive',
      Call: function (rowData) {
        return rowData.active === true ? 'Active' : 'Inactive';
      }
    },
    {
      datan: 'notes',
      name: 'Notes',
    },
    {
      datan: 'action',
      name: 'Action',
      cellWidth:'80px',
      Call: function (rowData) {
        return <>
          <CustomDropdownButton
            menuItems={[
              { label: 'View Module', onClick: () => handleViewModuleClick(rowData) },
              // { label: 'Edit Module',  onClick: () => handleItemClick1(rowData) },
              { label: 'Delete Module', onClick: () => openModel1(rowData._id)},
              { label: 'View Module History', onClick: () => navigate(`/historyModule`, { state: { history:rowData} })}
            ]}
          />
        </>;
      }
    }
  ];

  
  const openModel = () => { setMessage({errorMessage:'',successMessage:''});setModel(true) }
  const closeModel = () => { setIsChecked(false);setModel(false);setModuleValue("");setRows([{}]);setMessage({
    errorMessage:''
  });setSelectedOptions(['']);setError('');setTitle('');}
//-----------------Menu Functions --------------------------//

  const handleViewModuleClick = (data) => {
    console.log(data,"hhhhhhhhhhhhhhhhhhhh");
    navigate(`/viewModule`, { state: { data:data} });
  };


  const handleItemClick1 = (row) => {
    console.log("mmmmmmmmmmm",row);
    setTitle("Edit Module");
    setModuleValue(row);
    const indexValues = row?.moduleDocument.map((_, index) => index);
    // setRows(row?.moduleDocument,{});
    setRows([...row?.moduleDocument,{ documentId: '',fileTitle:'', dup: false, threeHole: false, stpl: false,sm: false,t_pk:false,t_sld:false,exam:false,quantity:'',googleFileId:'',recentRevision:'',recentReevDate:"",docTitles:"" }])
   
    openModel();
  
  };

  const handleDeleteAlert=async(id)=>{
    console.log("roufjuisfhf",ids);
    setLoad(true);
    const res = await deleteModAlert(id);
    setLoad(false);
    if( res.data?.message != "Module not used in Course" ){
      setDltMessage("This Module cannot be deleted as it is already linked with below Courses");
     }else{
      setDltMessage("Are you sure, you want Delete?");
     }
      setDltMod(res.data)
      setLoad(false);

    // if(res.status === 200){
    //   setModal1(false);
    //   setMessage({
    //     successMessage:res?.data?.message
    //   })
    //   setTimeout(() => {
    //     setMessage({
    //       successMessage:""
    //     })
    //   }, 4000);
    //   getAllModules(currentPage)
    // }else if(res?.response?.data?.message === "Module Used in Course"){
    //   setModal1(false);
    //   setMessage({
    //     message,
    //     errorMessage:"This Module is currently in use within Course and cannot be deleted"
    //   })
    //   getAllModules(currentPage)
    //   setTimeout(() => {
    //     setMessage({
    //       errorMessage:""
    //     })
    //   }, 4000);
    // }
    }
    const handleDelete=async()=>{
      setLoad(true);
      const res = await deleteMod(ids);
      setLoad(false);
      if(res.status === 200){
        setModal1(false);
        setMessage({
          successMessage:res?.data?.message
        })
        setTimeout(() => {
          setMessage({
            successMessage:""
          })
        }, 4000);
        getAllModules(currentPage)
      }else {
        setModal1(false);
        setMessage({
          message,
          errorMessage:"Somthing Went Wrong"
        })
        getAllModules(currentPage)
        setTimeout(() => {
          setMessage({
            errorMessage:""
          })
        }, 4000);
      }
      }
    


// ------------ drag ----------//
const handleDragEnd = (result) => {
  if (!result.destination) {
    return;
  }

  if (result.destination.index === rows.length - 1) {
    return; 
  }

  const items = Array.from(rows);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  setRows(items);
};

  
  
  function body() {
    return <>
      <Container>
      <ErrorMessage message={message?.errorMessage || ''}/>
        <Row  className='g-1' style={{border:'1px solid lightgrey',padding:'8px'}}>
        <Col md={12} lg={4}>
            {/* <InputField
                label="Name"
                required
                name="name"
                placeholder='Enter values'
                value={moduleValue?.name}
                onChange={handleChange}
            /> */}
                   <SelectComponent label={'Name'} 
    value={title ? moduleValue.name.split("-")[0]:moduleValue.name}
    options={option}
onChange={(value)=>handleSelect(value)}
disabled={title ? true : false}
required={true}
/> 
             {error === "Name is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
             {!moduleValue?._id ?
             <div className="container">
              <Form.Check
              type="checkbox"
              id="default"
              label="Do you want Custom Module Number"
              checked={isChecked}
              
              onChange={(event) => {
                setIsChecked(event.target.checked);
                setModuleValue((prevState) => ({
                  ...prevState, // Keep all other properties unchanged
                  moduleNumber: '',  // Set only the docTitle key to an empty string
                }));
              }}
            />
            </div>
            :
            ""
            }
             
            <InputField
              label="Duration"
              type='number'
              placeholder="Enter Duration"
              name="duration"
              value={moduleValue?.duration}
              inputmode="numeric"
              // accept='number'
              // required
              // value={inputValue}
              onChange={handleChange}
            />
      
    
            <CheckBoxComponent label="Active" checked={moduleValue?.active}   onChange={(event) => {
   setModuleValue({
    ...moduleValue,active:event.target.checked
   })
  }} /> 
   {/* <Form.Check aria-label="option 1" checked={row?.t_pk}   onChange={(e) => handleInputChange(e,index,"t_pk")} /> */}
          </Col>
          <Col md={12} lg={4}>
           
            <InputField
              label="Description"
              name="desc"
              value={moduleValue?.desc}
              // required
              placeholder='Enter Description'
              // value={inputValue}
              onChange={handleChange}
              // onChange={(e) => {
              //   const value = e.target.value.replace(/[\/\\]/g, '');
              //   handleChange({ target: { name: 'desc', value } });
              // }}
            />
             {isChecked ? 
                        <InputField
                        label="Module number"
                        name="moduleNumber"
                        placeholder='Enter Module number'
                        required
                        value={moduleValue.moduleNumber}
                        // onChange={handleChange}
                        onChange={(e) => {
                         
                         
                          const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 3);
                         console.log("e.target.value",value);
             
                         handleChange({ target: { name: 'moduleNumber', value } });
                       }}
                      />
                   :
                   null
                   }
            <SelectComponent label={'Type'}
            required={true}
            defaultValue={moduleValue?.type}
            value={moduleValue?.type}
             onChange={(newValue) => handleSelectChange(newValue, 'type')}
             options={[
                   { label: "Select the Type", value: "" },
                   { label: "Course Info", value: "Course Info" },
                   { label: "Lecture", value: "Lecture" },
                   { label: "Test", value: "Test" },
                   { label: "Workshop", value: "Workshop" },
                   { label: "Exams", value: "Exams" },
              ]} /> 
               {error === "Type is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
    
            <InputField
              label="Notes"
              name='notes'
              value={moduleValue?.notes}
              placeholder='Enter Notes'
            
              // value={inputValue}
              onChange={handleChange}
            />
            {/* </Stack> */}
          </Col>
          <Col md={12} lg={4}>
           
          <SelectComponent label={'Status'}
          required={true}
             defaultValue={moduleValue?.status}
             value={moduleValue?.status}
               onChange={(newValue) => handleSelectChange(newValue, 'status')}
              options={[
                    { label: "Select the Status", value: "" },
                   { label: "Draft", value: "Draft" },
                   { label: "Need Update", value: "Need Update" },
                   { label: "No Longer in Service", value: "No Longer in Service" },
                   { label: "Released", value: "Released" },
              ]} /> 
               {error === "Status is required"? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
            
               <InputField
             label="Tab Text"
             name='tabText'
             value={moduleValue?.tabText}
             placeholder='Enter Tab text'
            //  required
             // value={inputValue}
            //  onChange={handleChange}
             onChange={(e) => {
              const value = e.target.value.replace(/[\/\\]/g, '');
              handleChange({ target: { name: 'tabText', value } });
            }}
           />
            {error === "Tab text must be 28 characters or less."? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>: null}
           
         </Col>
        </Row>
        <Row style={{marginTop:'10px'}}>
          <Col>

    <div >
    <DragDropContext onDragEnd={handleDragEnd} >
          <Table >
      <thead >
        <tr>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Content</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Dup</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>3H</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Stpl</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>SM</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>T-Pk</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>T-Sld</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Exam</th>
          {/* <th style={{fontSize:'14px',fontWeight:'normal'}}>Sort</th> */}
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Qty</th>
          <th></th>
        </tr>
      </thead>
      <Droppable droppableId="droppable">
      {(provided) => (
        <tbody {...provided.droppableProps} ref={provided.innerRef}>
          {rows.map((row, index) => (
            <Draggable key={index} draggableId={String(index)} index={index} isDragDisabled={index === rows.length - 1}>
              {(provided) => (
                <React.Fragment>
                {index === 0 && error === "Select Document" && (
                  <tr>
                    <td colSpan="9" style={{ color: 'red' }}>{error}</td>
                  </tr>
                )}
                <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
    
            <td>
           
            <Select
                  options={options.filter(
                    options => !rows.slice(0, index).some(
                      row => row.documentId === options.value
                    )
                  )}
                  defaultValue={row?.documentId}
                  value={options.find(option => option.value === row.documentId)}
                  onChange={selectedOption => handleSelectChanges(index, selectedOption, "documentId","fileTitle")}
                />
          </td>
          <td>
          <Form.Check aria-label="option 1" checked={row?.dup}   onChange={(e) => handleInputChange(e,index,"dup")} />
          </td>
          <td>
          <Form.Check aria-label="option 1"  checked={row?.threeHole}   onChange={(e) => handleInputChange(e,index,"threeHole")} />
          </td>
          <td>
          <Form.Check aria-label="option 1"  checked={row?.stpl}   onChange={(e) => handleInputChange(e,index,"stpl")} />
          </td>
          <td>
          <Form.Check aria-label="option 1"  checked={row?.sm}   onChange={(e) => handleInputChange(e,index,"sm")} />
          </td>
          <td>
          <Form.Check aria-label="option 1"  checked={row?.t_pk}   onChange={(e) => handleInputChange(e,index,"t_pk")} />
          </td>
          <td>
          <Form.Check aria-label="option 1"  checked={row?.t_sld}   onChange={(e) => handleInputChange(e,index,"t_sld")} />
          </td>
          <td>
          <Form.Check aria-label="option 1"  checked={row?.exam}   onChange={(e) => handleInputChange(e,index,"exam")} />
          </td>
          <td>
          <Select
           value={quqntity.find(option => option.value === row.quantity)}
            onChange={selectedOption => handleSelectQuantity(index, selectedOption, "quantity")}
            options={quqntity} 
/>
          </td>
          <td>
          <CloseOutlined onClick={()=>removeIndex(row)}/>
          </td>
        </tr>
        </React.Fragment>
             )}
             </Draggable>
           ))}
           {provided.placeholder}
         </tbody>
       )}
     </Droppable>
        </Table>
        </DragDropContext>
        </div>

          </Col>
          </Row>
       
      </Container>
    </>
  }
  function footer() {
    return <>
      <Stack direction="horizontal" gap={2}>
        <FormButton onClick={closeModel}>Close</FormButton>
        <FormButton onClick={saveModel}>Save</FormButton>
      </Stack>
    </>
  }

  function header (){
    return<>
   <Stack direction="horizontal" gap={2}>
        <h3>{title ? title : "Add Module"}</h3>
    </Stack>
    </>
  }
  
 //--------------model1-----------//

 const [modal1,setModal1]=useState(false);
const openModel1 = (id) =>{
  handleDeleteAlert(id);
  console.log("hhhhh",id);
  setModal1(true)
  setIds(id);
}
const closeModel1 = () =>{
  setDltMessage("");
  setDltMod(['']);
  setModal1(false)
}

function body1(){
  return<>
  <Container>
     {dltMessage === "This Module cannot be deleted as it is already linked with below Courses" ?<p style={{color:'red'}}>{dltMessage}</p>  : dltMessage} 
  </Container>
  <div style={{display:'flex',flexDirection:'column',maxHeight:'150px', overflowY:'auto'}}>
    <ol>
    {dltMod?.data?.map((item)=>(
<li>
 {item.course_Name}
</li>
  ))
  }
    </ol>
  </div>
  </>
}

function footer1 (){
  return<>
 <Stack direction="horizontal" gap={2}>
      <FormButton onClick={closeModel1}>Close</FormButton>
      {dltMod?.message === "Module Used in Course" ?  <FormButton disabled onClick={handleDelete}>Delete</FormButton>: <FormButton  onClick={handleDelete}>Delete</FormButton> } 
  </Stack>
  </>
}
function header1 (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h3>Delete Module</h3>
  </Stack>
  </>
}

//----------------------remove doc-----------------------//
//------------------ remove module --------------//
const [removeId, setRemoveId]=useState('');
const openModel3 = (data) =>{
  setMod3(true);
  setRemoveId(data);

}
const closeModel3 = () =>{

  setMod3(false)
}
function header3(){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h3>{removeId?.fileTitle}</h3>
  </Stack>
  </>
}
function footer3 (){
  return<>
  <Row className="g-1" width={"100%"}>
        <Col md={12} lg={6}>
        <FormButton onClick={closeModel3}>Close</FormButton>
        </Col>
        <Col md={12} lg={6}>
        <FormButton onClick={()=>{setRows((prevRows) => prevRows.filter((r) => r.documentId !== removeId?.documentId));setMod3(false);}}>Remove</FormButton>
        </Col>
        </Row>
  </>
}

  

function body3(){
  return<>
   <Container>
      Are you sure! Do you want remove from the list?
  </Container>
  </>
}





  return (
    <div>
       {load === true && (
      <div className="spinner-container">
      <div className="text-center">
      <Spinner
        animation="border"
        role="status"
        style={{
          borderWidth: '2px', 
          width: '60px',   
          height: '60px',  
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div>Loading...</div>
    </div>
    </div>
    )}
        <SuccessMessage message={message?.successMessage || ''}/>
        <ErrorMessage message={message?.errorMessage || ''} />

      <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
        <ModalComponent
          title={header}
          status={set}
          onHide={closeModel}
          footer={footer}
          body={body}
          animation={true}
          size={'xl'}
        />
         <ModalComponent
        title={header1}
        status={modal1}
        onHide={closeModel1}
        footer= {footer1}
        body= {body1}
        animation={true}
       />
         <ModalComponent
          title={header3}
          status={mod3}
          onHide={closeModel3}
          footer={footer3}
          body={body3}
          animation={true}
          // size={'xl'}
        />
            <div style={{ width: '100%'}}> 
 <Row >
      <Col xl={4} lg={4} md={6} sm={12} style={{marginBottom:"2px",}}>
      <InputGroup style={{ boxShadow:'none'}} >
      {/* <InputGroup.Text>
       <SearchOutlined /> 
      </InputGroup.Text> */}
      <FormControl
       style={{boxShadow:'none'}}
        placeholder="Enter Module Name, Description, Status"
        onChange={search}
      />
    </InputGroup>
      </Col>
      <Col xl={4} lg={4} md={3} sm={12} style={{display:'flex',justifyContent:'flex-end'}}>
      </Col>
      <Col xl={4} lg={4} md={3} sm={12} style={{display:'flex',justifyContent:'flex-end'}}>
      <FormButton
          variant="primary"
          onClick={() => openModel()}
        >
          Add New Module
        </FormButton>
      </Col>
      </Row>
      </div>
      </div>
      <div >
        <TableComponent
          columns={columns}
          data={module}
        />
      </div>
      <CustomPagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} status={false} />

    </div>

  );
};

export default Module;
import React, {useEffect, useState } from 'react';
import { TableComponent, FormButton, ModalComponent, InputField,SelectComponent } from '../../component/index';
import { Col, Container, Row, Spinner, Stack } from 'react-bootstrap';
import CustomPagination from '../../functions/pagination';

import Sucessmsg from "../../component/messagecomponent/successMessage.jsx";
import { getRole } from '../../API/roleapi';
import SuccessMessage from '../../component/messagecomponent/successMessage';
import ErrorMessage from '../../component/messagecomponent/errorMessage';
import { getUsers, register } from '../../API/authapi';
// import BasicMenu from '../component/index';

const User = () => {
  const [user, setUser] = useState({
    name: "",
    role: "",
    email: "",
    password: "",
    re_password: "",
    state: ""
  });
  const [message, setMessage] = useState({
    sucessMessage:''
  });

const [error, setError] = useState('');
const [currentPage,setCurrentPage]=useState(1);
const [totalPage, setTotalPage]=useState('');
const [userData,setUserData]=useState('');
const [load,setLoad]=useState(false);

  useEffect(()=>{
    getAllData('');
  },[])

  useEffect(()=>{		

    getRoles();
  
   },[])
  const [options, setOptions]=useState([]);
  const getRoles = async()=>{
    try{
    const result = await getRole();
    console.log("result123",result);
    const mappedDocuments = result?.data?.data?.map((document) => ({
        label: document?.role,
        value: document?._id,
    }));
   setOptions(mappedDocuments);
  }
  catch (error) {
    console.log("error",error)
  }
  }

  const getAllData=async(newPage)=>{
    const token = localStorage.getItem('accessToken');
    console.log(token,"jkljh");
    const currentPage =newPage;
    setLoad(true);
   const res = await getUsers(currentPage,token);
   setLoad(false);
   setUserData(res?.users)
   setCurrentPage(res.currentPage);
   setTotalPage(res.totalPages);

  
  }

  const handlePageChange = (newPage) => {
    // Handle the page change here
    console.log('Page changed to:', newPage);
    getAllData(newPage);
  };
//---------registerUser--------------//
// const handleChange = (e) => {
//   console.log(e.target.value, "jkjkjj");
//   try {
//     setUser({
//       ...user,
//       [e.target.name]: e.target.value,
//     });
//   } catch (error) {
//     console.log(error);
//   }
// }

// const handlePageChange=(page)=>{
// console.log(page);
// }

const handleChange = (e) => {
  setError('');
  console.log("hhhh",e.target.value);
  setUser({
    ...user,
    [e.target.name]: e.target.value,
  });
  console.log("Updated User State:", user); 
}

const handleSelectChange = (newValue, selectName) => {
  if (selectName === 'Role') {
    setUser({
      ...user,
      role:newValue
    })
  } else if (selectName === 'Status') {
    setUser({
    ...user,
    state:newValue
    })
  }
};

const registerUser=async()=>{
  const token = localStorage.getItem('accessToken');
  console.log(user);
  // const userValid = Object.keys(user).every((key) => !!user[key]);
  if(user.name === ""){
    setError("Name is required");
  }else if(user.email === ""){
    setError("Email is required");
  }else if(user.role === ""){
    setError("Role is required");
  }else if(user.password === ""){
    setError("Password is required");
  }else if(user.re_password === ""){
    setError("Re-password is required");
  }else if(user.password != user.re_password){
    setError("Both password field should be same");
  }else if(user.state=== ""){
    setError("Status is required");
  }else{
 try{
  
  const userDtail = {
    name: user.name,
    role: user.role,
    email: user.email,
    password: user.password,
    state:user.state,
    // userType:localStorage.getItem("userType")
  }
setLoad (true);
  const result = await register(userDtail,token);
  setLoad(false)
  
  if(result.message === "Register successfully"){
    closeModel();
    setUser({
      name: "",
      role: "",
      email: "",
      password: "",
      re_password: "",
      state: ""
     });
    setMessage({
      message,
      successMessage:result?.message
    })
    setTimeout(() => {
      setMessage({
        successMessage:""
      })
    }, 4000);
  }else{
    console.log("ggg",result?.response?.data?.error);
    setError(result?.response?.data?.error);
  }
  }catch(error){
    console.log("error",error);
  }
}
}






  var columns = [
    {
      datan: 'name',
      name: 'Name',

    },
    {
      datan: 'email',
      name: 'Email',
    },
    {
      datan: 'role',
      name: 'Role',
     
    },
    {
      datan: 'state',
      name: 'Status',
      Call:function(rowData){
        return rowData?.state ? "Active" : "InActive"
      }
    },
    {
      datan: 'action',
      name: 'Action',
      width: '100px',
      Call: function (rowdata) {
        return (
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          
          <FormButton width={'60px'} variant="primary" onClick={() => edits(rowdata)}>Edit</FormButton>

        </div>
        )
      }
    },
    
  ];

  const [set, setModel] = useState(false);
  const openModel = () => { setModel(true) }
 const closeModel = () => { setModel(false);setUser('') }

  const edits=(rowdata)=>{
    setUser(rowdata);
    openModel()
  }
  

  function body() {
    // const [data,setData]=useState()
    console.log("userDetail12342", userData);
    console.log("userDetail12342", userData[0]?.name);
    return <>
       <Container>
        <Row className="g-1">
          <Col md={12} lg={6}>
          <InputField
        label="Name"
        required
        name="name"
        value={user?.name}
        onChange={handleChange}
      />
      
 {error === "Name is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
                <InputField
              label="Password"
              required
              name="password"
              value={user?.password }
              onChange={handleChange}
            />
            {error === "Password is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:
            error === 'Both password field should be same' ?<p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
            <InputField
              label="Re-Password"
              required
              name="re_password"
              value={user?.re_password}
              onChange={handleChange}
            
            />
{error === "Re-password is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:
 error === 'Both password field should be same' ?<p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
          </Col>
          <Col md={12} lg={6}>
            {/* <Stack gap={2} style={{height:'60vh',overflow:'auto'}}> */}
            <InputField
              label="Email"
              required
              name="email"
              value={user?.email}
              onChange={handleChange}
            />
            {error === "Email is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:
             error === 'Email Already Exists' ?<p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
            <SelectComponent label={'Role'} 
            required
            name="role"
            value={user?.role}
            onChange={(newValue) => handleSelectChange(newValue, 'Role')}
            options={options} /> 
{error === "Role is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
      <SelectComponent label={'Status'}
      required
      name="state"
      value={user?.state}
      onChange={(newValue) => handleSelectChange(newValue, 'Status')}
        options={[
    { label: "Select the Status", value: "" },
    { label: "Active", value: "true" },
    { label: "InActive", value: "false" },
  ]
} /> 
{error === "Status is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
        
           
            
          </Col>
        </Row>
      </Container>
    </>
  }
  function footer() {
    return <>
      <Stack direction="horizontal" gap={2}>
        <FormButton onClick={closeModel}>Close</FormButton>
        <FormButton onClick={registerUser}>Save</FormButton>
      </Stack>
    </>
  };

  function title() {
    return <>
      <Stack direction="horizontal" gap={2}>
        <h3>Add User</h3>
      </Stack>
    </>
  };

  return (

    <div >
    {load === true && (
   <div className="spinner-container">
   <div className="text-center">
   <Spinner
     animation="border"
     role="status"
     style={{
       borderWidth: '2px', 
       width: '60px',   
       height: '60px',  
     }}
   >
     <span className="visually-hidden">Loading...</span>
   </Spinner>
   <div>Loading...</div>
 </div>
 </div>
 )}
  
    <SuccessMessage message={message?.successMessage || ''}/>
 
   <ErrorMessage message={message?.errorMessage || ''} />



       <Sucessmsg message={message?.successMessage || ''}/>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
        <ModalComponent
          title={title}
          status={set}
          onHide={closeModel}
          footer={footer}
          body={body}
          animation={true}
        />
        <FormButton
          variant="primary"
          onClick={() => openModel( )}
        >
          Add User
        </FormButton>
      </div>
      <div >
        <TableComponent
         cellWidth="4px"
          columns={columns}
          data={userData}
        />
      </div>
      <CustomPagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />

    </div>

  );
};

export default User;
import React, { useEffect, useState } from 'react';
import { NavItem } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Menu from './menuData';
import '../assets/Menu.css';
import { useSelector } from 'react-redux';

// function MenuItem({ opens }) {
//   const menuItems = Menu.children;
//   const [activeItem, setActiveItem] = useState(0);
//   const [activeSubMenu, setActiveSubMenu] = useState(null);
//   const location = useLocation();

//   useEffect(() => {
//     const storedActiveIndex = parseInt(localStorage.getItem('activeIndex'));
//     const storedActiveSubMenu = parseInt(localStorage.getItem('activeSubMenu'));

//     if (!isNaN(storedActiveIndex)) {
//       setActiveItem(storedActiveIndex);
//     }

//     if (!isNaN(storedActiveSubMenu)) {
//       setActiveSubMenu(storedActiveSubMenu);
//     }
//   }, []);

//   useEffect(() => {
//     const currentRouteIndex = menuItems.findIndex(item => item.url === location.pathname);
//     if (currentRouteIndex !== -1) {
//       setActiveItem(currentRouteIndex);
//       localStorage.setItem('activeIndex', currentRouteIndex.toString());

//       if (menuItems[currentRouteIndex].children) {
//         // Check if there's a submenu and set its index
//         setActiveSubMenu(localStorage.getItem('activeSubMenu') || 0);
//       } else {
//         setActiveSubMenu(null);
//       }
//     }
//   }, [menuItems, location.pathname]);

//   const handleMainMenuItemClick = (index) => {
//     setActiveItem(index);
//     localStorage.setItem('activeIndex', index.toString());

//     if (menuItems[index].children) {
//       setActiveSubMenu(localStorage.getItem('activeSubMenu') || 0);
//     } else {
//       setActiveSubMenu(null);
//     }
//   };

//   const handleSubMenuItemClick = (index) => {
//     setActiveSubMenu(index);
//     localStorage.setItem('activeSubMenu', index.toString());
//   };

//   return (
//     <div style={{ width: opens ? '100%' : null }}>
//       {menuItems.map((item, index) => (
//         <Link to={item.url} key={item.id} style={{ textDecoration: "none" }}>
//           <div>
//             <NavItem
//               className={`main-menu ${index === activeItem ? 'active-item' : 'hdh'}`}
//               onClick={() => handleMainMenuItemClick(index)}
//             >
//               <div className={'items-menu'}>
//                 <span className={index === activeItem ? 'anticon' : 'anticon1'}>{item.icon}</span>
//                 <span className={index === activeItem ? 'fonts' : 'fonts1'}>{item.title}</span>
//               </div>
//             </NavItem>
//             {item.children && index === activeItem && (
//               <div className="sub-menu">
//                 {item.children.map((subItem, subIndex) => (
//                   <Link to={subItem.url} key={subItem.id} style={{ textDecoration: "none" }}>
//                     <div key={subItem.id}>
//                       <NavItem
//                         className={`submenu ${subIndex === activeSubMenu ? 'active-item' : 'hdh'}`}
//                         onClick={() => handleSubMenuItemClick(subIndex)}
//                       >
//                         <div className={'items-submenu'}>
//                           <span className={subIndex === activeSubMenu ? 'anticon' : 'anticon1'}>{subItem.icon}</span>
//                           <span className={subIndex === activeSubMenu ? 'fonts' : 'fonts1'}>{subItem.title}</span>
//                         </div>
//                       </NavItem>
//                     </div>
//                   </Link>
//                 ))}
//               </div>
//             )}
//           </div>
//         </Link>
//       ))}
//     </div>
//   );
// }

// export default MenuItem;


function MenuItem({ opens }) {
  const user = useSelector(state => state?.user?.authReducer?.loginResponse?.data);
  
  const menuItem= Menu.children;
  const [activeItem, setActiveItem] = useState(0);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const location = useLocation();
  const[menuItems, setMenuItems]=useState([]);
 useEffect(()=>{
  if(user.role != "67a5f434e5aa9c6c0e79f724"){
    
    // const filteredItems = menuItem.filter((item) => item.id !== "UserManagement");
    const itemsToRemove = ["UserManagement", "accessControl"];
const filteredItems = menuItem.filter((item) => !itemsToRemove.includes(item.id));
    setMenuItems(filteredItems || []);
  }else{
    setMenuItems(menuItem || []);
  }
 
 },[user]);
  useEffect(() => {
    const storedActiveIndex = parseInt(localStorage.getItem('activeIndex'));
    const storedActiveSubMenu = parseInt(localStorage.getItem('activeSubMenu'));

    if (!isNaN(storedActiveIndex)) {
      setActiveItem(storedActiveIndex);
    }

    if (!isNaN(storedActiveSubMenu)) {
      setActiveSubMenu(storedActiveSubMenu);
    }
  }, []);

  useEffect(() => {
    const currentRouteIndex = menuItems.findIndex(item => item.url === location.pathname);
    if (currentRouteIndex !== -1) {
      setActiveItem(currentRouteIndex);
      localStorage.setItem('activeIndex', currentRouteIndex.toString());

      if (menuItems[currentRouteIndex].children) {
        setActiveSubMenu(localStorage.getItem('activeSubMenu') || 0);
      } else {
        setActiveSubMenu(null);
      }
    }
  }, [menuItems, location.pathname]);

  const handleMainMenuItemClick = (index) => {
    setActiveItem(index);
    localStorage.setItem('activeIndex', index.toString());

    if (menuItems[index].children) {
      setActiveSubMenu(localStorage.getItem('activeSubMenu') || 0);
    } else {
      setActiveSubMenu(null);
    }
  };

  const handleSubMenuItemClick = (index) => {
    setActiveSubMenu(index);
    localStorage.setItem('activeSubMenu', index.toString());
  };

  return (
    <div style={{ width: opens ? '100%' : null }}>
      {menuItems?.map((item, index) => (
        <Link to={item.url} key={item.id} style={{ textDecoration: "none" }}>
          <div>
            <NavItem
              className={`main-menu ${index === activeItem ? 'active-item' : 'hdh'}`}
              onClick={() => handleMainMenuItemClick(index)}
            >
              <div className={'items-menu'}>
                <span className={index === activeItem ? 'anticon' : 'anticon1'}>{item.icon}</span>
                <span className={index === activeItem ? 'fonts' : 'fonts1'}>{item.title}</span>
                {/* {item.children && <span className="submenu-arrow">&#9654;</span>} */}
              </div>
            </NavItem>
            {item.children && index === activeItem && (
              <div className="sub-menu">
                {item.children.map((subItem, subIndex) => (
                  <Link to={subItem.url} key={subItem.id} style={{ textDecoration: "none" }}>
                    <div key={subItem.id}>
                      <NavItem
                        className={`submenu ${subIndex === activeSubMenu ? 'active-item' : 'hdh'}`}
                        onClick={() => handleSubMenuItemClick(subIndex)}
                      >
                        <div className={'items-submenu'}>
                          <span className={subIndex === activeSubMenu ? 'anticon' : 'anticon1'}>{subItem.icon}</span>
                          <span className={subIndex === activeSubMenu ? 'fonts' : 'fonts1'}>{subItem.title}</span>
                        </div>
                      </NavItem>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </Link>

        
      ))}
    </div>
  );
}

export default MenuItem;

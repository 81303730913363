// import React, { useEffect, useState } from "react";
// import { FormButton, InputField, SelectComponent } from "../../component";
// import { Button } from "react-bootstrap";
// import { getRole } from "../../API/roleapi";

// function Permission() {
//   const [roles, setRoles] = useState([]);
//   const [selectedRole, setSelectedRole] = useState("Admin");
//   const [modules, setModules] = useState([]);
//   const [selectedModule, setSelectedModule] = useState("Document");
//   const [permissions, setPermissions] = useState({});

//   const [json, setJson] = useState([
//     { Module: "Document", Functionalities: ["View", "Add", "Edit", "Delete"] },
//     { Module: "Course", Functionalities: ["Add", "Delete", "FileChange", "History"] },
//     { Module: "Module", Functionalities: ["View", "Add", "Edit", "Delete"] },
//   ]);



//   useEffect(() => {
//     fetchRoles();
//     fetchModules();
//     if (selectedRole && selectedModule) {
//       fetchPermissions(selectedRole, selectedModule);
//     }
//   }, [selectedRole, selectedModule]);

 

//   const fetchRoles = async()=>{
//     try{
//     const result = await getRole();
//     console.log("result123",result);
//     const mappedDocuments = result?.data?.data?.map((document) => ({
//         label: document?.role,
//         value: document?._id,
//     }));
//     setRoles(mappedDocuments || []);
//   }
//   catch (error) {
//     console.log("error",error)
//   }
//   }

//   const fetchModules = async () => {
//     const moduleNames = json.map((item) => item.Module);
//     const mappedDocuments =moduleNames.map((item) => ({
//       label: item,
//       value: item,
//   }));
//     setModules(mappedDocuments);
//   };

//   const fetchPermissions = async (roleId, moduleName) => {
//     const data = await fetch(
//       `/api/permissions?roleId=${roleId}&moduleName=${moduleName}`
//     ).then((res) => res.json());
//     setPermissions((prev) => ({
//       ...prev,
//       [roleId]: {
//         ...(prev[roleId] || {}),
//         [moduleName]: data,
//       },
//     }));
//   };

//   const handlePermissionChange = (roleId, module, action) => {
//     setPermissions((prevPermissions) => ({
//       ...prevPermissions,
//       [roleId]: {
//         ...prevPermissions[roleId],
//         [module]: {
//           ...(prevPermissions[roleId]?.[module] || {}),
//           [action]: !prevPermissions[roleId]?.[module]?.[action],
//         },
//       },
//     }));
//   };

//   const savePermissions = () => {
//     const rolePermissions = permissions[selectedRole]?.[selectedModule];
//     fetch(`/api/permissions?roleId=${selectedRole}&moduleName=${selectedModule}`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(rolePermissions),
//     })
//       .then((res) => res.json())
//       .then(() => alert("Permissions saved successfully"));
//   };

//   const getFunctionalities = () => {
//     const moduleData = json.find((item) => item.Module === selectedModule);
//     return moduleData?.Functionalities || [];
//   };

//   const handleSelectChange = (newValue, selectName) => {
    
//     setSelectedRole(
//         newValue
//       )
    
//   };

//   return (
//     <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
//       <h3>Assign Permissions</h3>
//       <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
//         {/* Role Selector */}
//         <div>
//           {/* <label>
//             <strong>Role:</strong>
//           </label> */}
//           <SelectComponent label={'Role'} 
//             required
//             name="role"
//             value={selectedRole}
//             onChange={(newValue) => handleSelectChange(newValue, 'Role')}
//             options={roles} /> 
//         </div>

//         {/* Module Selector */}
//         <div>
        
//           <SelectComponent label={'Module'} 
//             required
//             name="role"
//             value={selectedModule}
//             onChange={(newValue) =>{setSelectedModule(newValue)}}
//             options={modules} /> 
//           {/* <select
//             onChange={(e) => setSelectedModule(e.target.value)}
//             value={selectedModule}
//             style={{
//               padding: "10px",
//               marginLeft: "10px",
//               borderRadius: "5px",
//               border: "1px solid #ccc",
//             }}
//           >
//             <option value="" disabled>
//               Select Module
//             </option>
//             {modules.map((module, index) => (
//               <option key={index} value={module}>
//                 {module}
//               </option>
//             ))}
//           </select> */}
//         </div>
//       </div>

//       {/* Cards for Functionalities */}
//       {selectedModule && (
//         <div
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             gap: "20px",
//             marginTop: "20px",
//           }}
//         >
//           {getFunctionalities().map((func, index) => (
//             <div
//               key={index}
//               style={{
//                 border: "1px solid #ccc",
//                 borderRadius: "5px",
//                 padding: "20px",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 width: "300px",
//               }}
//             >
//               <strong>{func}</strong>
//               <Button
//                 variant={
//                   permissions[selectedRole]?.[selectedModule]?.[func]
//                     ? "success"
//                     : "secondary"
//                 }
//                 onClick={() =>
//                   handlePermissionChange(selectedRole, selectedModule, func)
//                 }
//               >
//                 {permissions[selectedRole]?.[selectedModule]?.[func]
//                   ? "Enabled"
//                   : "Disabled"}
//               </Button>
//             </div>
//           ))}
//         </div>
//       )}

//       {/* Save Button */}
//       {selectedRole && selectedModule && (
//         <div style={{ marginTop: "20px" }}>
//           <FormButton onClick={savePermissions}>Save</FormButton>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Permission;



import React, { useEffect, useState } from "react";
import { FormButton, SelectComponent } from "../../component";
import { Button } from "react-bootstrap";
import { getRole } from "../../API/roleapi";
import { getPermissions, savePermissionsAPI } from "../../API/permissionApi";
import { isEmptyArray } from "formik";


function Permission() {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [permissions, setPermissions] = useState({});

  const [json, setJson] = useState([
    { Module: "Document", Functionalities: ["View", "Add", "Edit", "Delete","File Replace"] },
    { Module: "Course", Functionalities: ["Add", "Delete", "FileChange", "History"] },
    { Module: "Module", Functionalities: ["View", "Add", "Edit", "Delete"] },
  ]);

  useEffect(() => {
    fetchRoles();
    fetchModules();
  
  }, []);
  useEffect(()=>{
    if (selectedRole && selectedModule) {
      const moduleData = json.find((item) => item.Module === selectedModule);

      setPermissions(moduleData);
      fetchPermissions(selectedRole, selectedModule);
    }
  },[selectedModule,selectedRole])

  const fetchRoles = async () => {
    try {
      const result = await getRole();
      // console.log("result?.data",result?.data);
      
      const mappedRoles = result?.data?.data
  ?.filter(role => role._id !== "67a5f434e5aa9c6c0e79f724")
  .map(role => ({
    label: role?.role,
    value: role?._id,
  }));
      // const mappedRoles = result?.data?.data?.map((role) => ({
      //   label: role.role,
      //   value: role._id,
      // }));
      console.log("mappedRoles",mappedRoles);
      
      setRoles(mappedRoles);
      setSelectedRole(mappedRoles?.[0]?.value || "");
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchModules = () => {
    const moduleNames = json.map((item) => item.Module);
    const mappedModules = moduleNames?.map((module) => ({
      label: module,
      value: module,
    }));
    setModules(mappedModules);
    setSelectedModule(mappedModules?.[0]?.value || ""); // Default to first module
  };

  const fetchPermissions = async (roleId, moduleName) => {
    try {
      const data = await getPermissions(roleId, moduleName);
      console.log("data",data);
      if (!data?.data || Object.keys(data.data).length === 0) {
        console.warn("No permissions found. Initializing default permissions.");
        const moduleData = json.find((item) => item.Module === selectedModule);
        setPermissions(moduleData);
      }else{
      setPermissions((prev) => ({
        ...prev,
        [roleId]: {
          ...(prev[roleId] || {}),
          [moduleName]: data?.data,
        },
      }));
    }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handlePermissionChange = (roleId, module, action) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [roleId]: {
        ...prevPermissions[roleId],
        [module]: {
          ...(prevPermissions[roleId]?.[module] || {}),
          [action]: !prevPermissions[roleId]?.[module]?.[action],
        },
      },
    }));
  };

  const savePermissions = async () => {
    const rolePermissions = permissions[selectedRole]?.[selectedModule];
    console.log("ffff",rolePermissions);
    
    try {
      await savePermissionsAPI(selectedRole, selectedModule, rolePermissions);
      alert("Permissions saved successfully");
    } catch (error) {
      alert("Failed to save permissions");
      console.error("Error saving permissions:", error);
    }
  };

  const getFunctionalities = () => {
    const moduleData = json.find((item) => item.Module === selectedModule);
    return moduleData?.Functionalities || [];
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h3>Assign Permissions</h3>
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <SelectComponent
          label="Role"
          required
          name="role"
          value={selectedRole}
          onChange={(newValue) => setSelectedRole(newValue)}
          options={roles}
        />
        
        <SelectComponent
          label="Module"
          required
          name="module"
          value={selectedModule}
          onChange={(newValue) => setSelectedModule(newValue)}
          options={modules}
        />
      </div>

      {selectedModule && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {getFunctionalities().map((func, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "300px",
              }}
            >
              <strong>{func}</strong>
              <Button
                variant={
                  permissions[selectedRole]?.[selectedModule]?.[func]
                    ? "success"
                    : "secondary"
                }
                onClick={() =>
                  handlePermissionChange(selectedRole, selectedModule, func)
                }
              >
                {permissions[selectedRole]?.[selectedModule]?.[func]
                  ? "Enabled"
                  : "Disabled"}
              </Button>
            </div>
          ))}
        </div>
      )}

      {selectedRole && selectedModule && (
        <div style={{ marginTop: "20px" }}>
          <FormButton onClick={savePermissions}>Save</FormButton>
        </div>
      )}
    </div>
  );
}

export default Permission;

import apiInstance from "./baseApi"
import { HandleLogout } from '../functions/logout.jsx';

export const getAllDoc = async (currentPage,id,name,srt) => {
  console.log(currentPage,id,name,srt,"jjjjj");
    try{
        const heder={
            headers: {
                "authorization":localStorage.getItem("accessToken")
            }
        }
        
          const response = await apiInstance.get(`doc/getAllDoc?page=${currentPage}&id=${id}&${name}&srt=${srt}`,heder);
          return response;
        
    }catch(err){
      if(err?.response?.statusText === "Unauthorized"){
        HandleLogout();
      }
        return err;
    }
}

export const addDoc = async (formData)=>{ 
    const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
      try {
        const response = await apiInstance.post('doc/uploadDoc', formData, headers);
        return response;
      } catch (error) {
        console.error('Error uploading file:', error);
        return error;
      }
}

export const editDoc = async (formData)=>{ 
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    try {
      console.log("ghdgdfgdfgdf");
      const response = await apiInstance.patch('doc/editDocument', formData, headers);
      console.log("ttttt",response);
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
    }
}


export const deleteDoc = async (id)=>{ 
 
    try {
      const response = await apiInstance.delete(`doc/deleteDoc`,{
        data:{id} ,
        headers: {
          "authorization": `${localStorage.getItem('accessToken')}`,
        }
    });
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
    }
}


export const addDocumentType = async (data)=>{ 
  
    try {
      const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
      const response = await apiInstance.post('documentTypes/uploadDocumetTypes', data, headers);
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
    }
}

export const getDocumentType = async ()=>{ 
 
    try {
      const headers = {
        headers: {
          "authorization": localStorage.getItem("accessToken")
        }
      }
      const response = await apiInstance.get('documentTypes/getDocumetTypes', headers);
      return response;
    } catch (error) {
      console.error('Error uploading file:', error);
      return error;
    }
}

export const updateRev = async (data)=>{ 
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    try {
      const response = await apiInstance.post('doc/updateRev', data, headers);
      return response;
    } catch (error) {
      return error;
    }
}

export const History = async (data)=>{ 
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    try {
      const response = await apiInstance.get(`doc/DocumentHistory?id=${data.id}`,headers);
      return response;
    } catch (error) {
      return error;
    }
}
export const EditeTitle = async (data)=>{ 
  const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    try {
      const response = await apiInstance.post(`doc/EditeTitle`,data,headers);
      return response;
    } catch (error) {
      return error;
    }
}

export const deleteDocuments = async (data) => {
  try {
    const response = await apiInstance.delete(`doc/deleteDocuments?id=${data.id}`, {
      headers: {
        "authorization": `${localStorage.getItem('accessToken')}`,
      }
    });
    return response;
  } catch (error) {
    console.error('Error deleting document:', error);
    return error;
  }
};

export const Testdc = async (data) => {
  try {
    const response = await apiInstance.post(`doc/testDocument`,data,{
      headers: {
        "authorization": `${localStorage.getItem('accessToken')}`,
      }
    });
    return response;
  } catch (error) {
    console.error('Error deleting document:', error);
    return error;
  }
};

export const downloadDocList = async () => {
  try {
    const headers = {
      headers: {
        "authorization": localStorage.getItem("accessToken")
      }
    }
    const response = await apiInstance.get(`doc/DocumentListGenerate`,headers);
    console.log("vvvvv",response);
    return response;
  } catch (error) {
    console.error('Error deleting document:', error);
    return error;
  }
};
